import React from 'react'
import {isMobile, Event, LOGO_MOBILE, CONTACT_NUMBER, encode, BORDER_RADIUS} from "../../../../Helper";
import Wallet from "../../../Components/User/Wallet";
import {Nav, NavItem} from "reactstrap";
import {NavLink} from "react-router-dom";
import Logout from "../../Auth/Logout";
import Credit from "../Credit";
import Setting from "../../../Components/User/Setting";
import RealTime from "../RealTime";
import {Divider, Grid} from "@mui/material";
import WalletDialog from "../../../Components/User/Wallet/WalletDialog";
import socket from "../../../../Socket";
import C from "../../../../Constant";
import storage from "../../../../Storage";
import Cookies from "js-cookie";
import HowToPlay from "../Help/HowToPlay";
import Button from "@mui/material/Button";
import AccountMenu from "../../../Components/User/AccountMenu";
import AccountSetting from "../../../Components/User/Setting/AccountSetting";

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            header: null,
            show: true,
            name: storage.getKey('name'),
        }
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        this.setState({header: this.dan()});
        /*if (!isMobile()) {
            this.setState({header: this.desktop()});
        } else {
            this.setState({header: this.mobile()});
        }*/
    }

    collapse = () => {
        this.setState({show: !this.state.show})
        Event.emit('toggle_sidebar', this.state.show);
    }

    showVerifyMpesa() {
        Event.emit('show_verify_mpesa');
    }

    showHelp() {
        Event.emit('show_help');
    }

    dan = () => {
        const {t} = this.props;
        let {name} = this.state;
        return (
            <Grid className={'mb-2'} container spacing={3}>
                <Grid item xs={2}>
                    <NavLink style={{marginRight: '0.16rem'}} className={"navbar-brand"} to={"/"}>
                        <img src={LOGO_MOBILE} style={{height: '31px'}}/>
                    </NavLink>
                </Grid>
                <Grid item xs>
                    <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                        <RealTime t={t}/>
                        <WalletDialog t={t}/>
                        <HowToPlay t={t}/>

                        <Divider className={"m-1"} color={"white"} orientation="vertical" flexItem/>

                        <Button className={'bg-image-custom'} style={{color: 'white'}} onClick={this.showHelp} size={'small'} name={'help'}>
                            Help
                        </Button>

                        <Divider className={"m-1"} color={"white"} orientation="vertical" flexItem/>
                        <Credit t={t}/>
                        <Divider className={"m-1"} color={"white"} orientation="vertical" flexItem/>
                        <AccountSetting/>
                        <AccountMenu name={name}/>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    desktop = () => {
        const {t} = this.props;

        return (
            <nav id={"topbar"} className="p-0 navbar navbar-expand-md navbar-light" role="navigation">
                <div className="container-fluid pl-1 pr-0">
                    <RealTime t={t}/>
                    <Wallet t={t}/>
                    <NavLink to="#" className="nav-link btn-sm btn-outline-success ml-2"
                             onClick={this.showVerifyMpesa}>{isMobile() ? '' :
                        <i className={"fa fa-wallet text-danger"}/>} VERIFY DEPOSIT</NavLink>
                    <a className={"ml-2 btn-sm nav-link text-white"} href={"tel:" + CONTACT_NUMBER}
                       style={{border: '1px solid #213743', borderRadius: BORDER_RADIUS}}><i
                        className={"fa fa-phone font-weight-bold"}></i> {CONTACT_NUMBER}</a>
                    <Nav className="ml-auto">
                        <NavItem>
                            <Credit t={t}/>
                        </NavItem>
                        <NavItem>
                            <NavLink to="/referral" className="nav-link text-white"><i
                                className={"mdi mdi-currency-usd"}></i> Referral</NavLink>
                        </NavItem>
                        <NavItem>
                            <Setting t={t}/>
                        </NavItem>
                        <NavItem>
                            <Logout t={t}/>
                        </NavItem>
                    </Nav>
                </div>
            </nav>
        );
    }

    mobile = () => {
        const {t} = this.props;

        return (
            <nav id={"topbar"} className={"p-0 navbar navbar-expand-md navbar-light"} role={"navigation"}>
                <div className={"container-fluid pl-0 pr-0"}>
                    <RealTime t={t}/>
                    <NavLink style={{marginRight: '0.16rem'}} className={"navbar-brand"} to={"/"}>
                        <img src={LOGO_MOBILE} style={{height: '31px'}}/>
                    </NavLink>
                    <Wallet t={t}/>
                    <NavLink to="#" className="nav-link btn-sm btn-custom ml-1"
                             onClick={this.showVerifyMpesa}>{isMobile() ? '' :
                        <i className={"fa fa-wallet text-danger"}/>} VERIFY</NavLink>
                    <Nav className={"ml-auto"}>
                        <NavItem className={'text-right pr-1'} style={{lineHeight: '0.75rem'}}>
                            <Credit t={t}/>
                        </NavItem>
                    </Nav>
                </div>
            </nav>
        );
    }

    render() {
        return this.state.header;
    }
}

export default Header;