import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import storage from "../../../Storage";
import socket from "../../../Socket";
import C from "../../../Constant";
import {encode, Event, wait} from "../../../Helper";
import Cookies from "js-cookie";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AccountSetting from "./Setting/AccountSetting";

export default function AccountMenu() {

    const name = storage.getKey('name');

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const logOut = () => {
        socket.emit(C.LOGOUT_USER, encode({token: storage.getKey('token')}));
        localStorage.clear();
        Cookies.remove("session");
        Cookies.remove("auth");
        Cookies.remove("uid");
        window.location.replace('./');
    }

    const showAccountSetting = () => {
        wait(300).then(() => {
            Event.emit('show-account-setting');
        })
    }

    return (
        <React.Fragment>
            <MenuIcon onClick={handleClick} style={{color: 'whitesmoke'}}/>

            <Menu anchorEl={anchorEl} id={"account-menu"} open={open} onClose={handleClose} onClick={handleClose}
                  PaperProps={{
                      elevation: 6,
                      sx: {
                          overflow: 'visible',
                          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                          mt: 1.5,
                          bgcolor: '#64A91F',
                          color: 'white',
                          className: 'testing',
                          '& .MuiMenuItem-root': {
                              minHeight: '0px',
                              paddingTop: '3px',
                              paddingBottom: '3px',
                              paddingLeft: '10px',
                              paddingRight: '26px',
                          },
                          '&:before': {
                              content: '""',
                              display: 'block',
                              position: 'absolute',
                              top: 0,
                              right: 14,
                              width: 10,
                              height: 10,
                              bgcolor: '#64A91F',
                              transform: 'translateY(-50%) rotate(45deg)',
                              zIndex: 0,
                          },
                      },
                  }}
                  transformOrigin={{horizontal: 'right', vertical: 'top'}}
                  anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            >

                <MenuItem>
                    <ListItemIcon>
                        <AccountCircleIcon/>
                    </ListItemIcon>
                    {name}
                </MenuItem>

                <MenuItem onClick={showAccountSetting}>
                    <ListItemIcon>
                        <ManageAccountsIcon/>
                    </ListItemIcon>
                    Change Password
                </MenuItem>

                <Divider style={{color: 'white', borderColor: 'white'}}/>

                <MenuItem onClick={logOut}>
                    <ListItemIcon>
                        <ExitToAppIcon/>
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}