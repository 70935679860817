import React from "react";
import {Col, Form, Row} from "react-bootstrap";
import storage from "../../../../Storage";
import socket from "../../../../Socket";
import {decode, encode, wait, sendNotification, Event} from "../../../../Helper";
import C from "../../../../Constant";
import LockIcon from "@mui/icons-material/Lock";
import Button from "@mui/material/Button";
import SaveIcon from '@mui/icons-material/Save';

class Security extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            disabled: false,
            password: '',
            _password: '',
        };
        this.saveUserSetting = this.saveUserSetting.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        socket.on(C.EDIT_PASSWORD, data => this.updateProfile(decode(data)));
    }

    componentWillMount() {
        wait(500).then(() => {
            this.setState({loading: false});
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    saveUserSetting(e) {
        if (this._isMounted) {
            wait(750).then(() => {
                socket.emit(C.EDIT_PASSWORD, encode({
                    token: storage.getKey('token'),
                    password: this.state.password
                }));
            })
        }
    }

    updateProfile(data) {
        if (this._isMounted) {
            this.setState({disabled: false});
            if (data.status) {
                Event.emit('close-account-setting');
                return sendNotification('Password updated successfully', 'success', 'top-center');
            } else {
                return sendNotification(data.error, 'danger', 'top-center');
            }
        }
    }

    back = () => {
        this.props.clicked();
    };

    render() {
        return (
            <>
                {this.state.loading ?
                    <>
                        <div className="text-center" style={{minHeight: 150}}>
                            <div className="spinner-border text-info my-2 user-loader" role="status"/>
                        </div>
                    </>
                    :
                    <Form style={{display: 'contents'}}
                          onSubmit={e => {
                              e.preventDefault();

                              if (!this.state.password) {
                                  document.getElementById('password').classList.add('border-danger');
                                  return;
                              }

                              if (!this.state._password) {
                                  document.getElementById('_password').classList.add('border-danger');
                                  return;
                              }

                              if (this.state.password !== this.state._password) {
                                  sendNotification('Passwords are different!', 'danger', 'top-center');
                              }

                              if (this.state.password === this.state._password) {
                                  this.setState({disabled: true});
                                  this.saveUserSetting(e);
                              }
                          }}
                    >

                        <div className={'custom-card m-2 w-100'}>
                            <span className={'text-white custom-label'}>New Password&nbsp;</span>
                            <input required={true} autoComplete={'off'} type={"password"} id={"password"} className={"text-strong form-control input-number custom-input"}
                                   name={"password"}
                                   value={this.state.password} onChange={e => this.setState({password: e.target.value})}/>
                            <LockIcon className={'text-white'}/>
                        </div>

                        <div className={'custom-card m-2 w-100'}>
                            <span className={'text-white custom-label'}>Confirm Password&nbsp;</span>
                            <input required={true} autoComplete={'off'} type={"password"} id={"_password"} className={"text-strong form-control input-number custom-input"}
                                   name={"password"}
                                   value={this.state._password} onChange={e => this.setState({_password: e.target.value})}/>
                            <LockIcon className={'text-white'}/>
                        </div>

                        <Button size={'large'} variant="contained" className={'custom-button m-2'} type={'submit'} fullWidth sx={{m: 2}}
                                disabled={this.state.disabled}><SaveIcon/>&nbsp;Save New Password</Button>

                    </Form>
                }
            </>
        );
    }
}

export default Security;