import * as React from 'react';
import {Component} from "react";
import storage from "../../../../Storage";
import {CONTACT_NUMBER, sendNotification, Event} from "../../../../Helper";
import {Modal} from "react-bootstrap";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Security from "./Security";

class AccountSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            initState: 0,
            phone: storage.getKey('phone'),
            name: storage.getKey('name'),
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {
        Event.on('show-account-setting', () => {
            this.handleShow();
        });

        Event.on('close-account-setting', () => {
            this.handleClose();
        });
    }

    handleClose() {
        this.setState({show: false, effect: 'zoomOut'});
    }

    handleShow() {
        if (storage.getKey('token') === null) {
            return sendNotification('Please Login to use this option.', 'warning', 'top-right');
        }

        this.setState({show: true, effect: 'pulse'});
    }

    render() {
        let {show, effect} = this.state;

        return (
            <Modal contentClassName={'custom-modal-content'} size="lg" centered={true} backdrop={"static"} show={show} onHide={this.handleClose}
                   aria-labelledby="promo-md-modal"
                   className={'animated ' + effect}>
                <Modal.Header className={'p-1'} closeButton>
                    <Modal.Title style={{color: 'antiquewhite', fontSize: '1.2rem'}}>
                        <AccountCircleIcon/>&nbsp;{this.state.name} | {this.state.phone}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{fontSize: '0.81rem'}} className={'m-0'}>
                    <Security clicked={false}/>
                </Modal.Body>
                <Modal.Footer className={'p-2'}>
                    For help, call: {CONTACT_NUMBER}
                </Modal.Footer>
            </Modal>
        )
    }
}

export default AccountSetting;